.btn-default{
  @include button-outline-variant($secondary);
}

.btn-default.bootbox-cancel{
  @include button-variant($secondary, $secondary);
}

.btn-move{
  @extend %fa-icon;
  @extend .fas;

  padding-top: 10px;

  &:before {
    content: fa-content($fa-var-arrows-alt);
  }
}

.btn-move.btn-sm{
  padding-top: 8px;
}

.backWebsite{
  background-color: #7f181a;
}
