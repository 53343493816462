.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.splash-screen{
  background-color: #eee;
}

.splash-container {
    max-width: 401px;
    margin: 50px auto;
}

.bodyLogin{
  background-image:url('../../../../front/img/slider.png');
  background-size: 100%;
  background-repeat: no repeat;
}

.login{
  h3{
    color: #7f181a;
  }
  .input-group-text{
    border:none;
  }
  .active{
    border-bottom-color:#7f181a;
    border-bottom: solid 2px;
    border-radius: 0;
    .fade {
      opacity: 1;
      transition: opacity .25s ease-in-out;
      -moz-transition: opacity .25s ease-in-out;
      -webkit-transition: opacity .25s ease-in-out;
      }
  }
  .voile_grise_carousel{
    position: absolute;
    top:0;
    height:100%;
    width:100%;
    background-color:#00000096;
    .text-center{
      padding-top:15%;
      color:white;
      font-size: 25px;
      text-transform: uppercase;
      font-weight: 300;
    }
  }
  input{
    border-top:none;
    border-right:none;
    border-left:none;
  }
  .btn{
    background-color:#7f181a;
    border-color: #7f181a;
  }
  .btn:hover{
    background-color:#000;
  }
  a{
    color:#7f181a;
  }
}

