@mixin role($bg-color) {
	background-color: $bg-color;
}

.super_admin{
	@include role($color-red);
}

.admin{
	@include role($color-blue);
}

.user{
	@include role($color-grey);
}

.debarrasseur{
	@include role($color-indigo);
}

.debarrasse{
	@include role($color-teal);
}
